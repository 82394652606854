import { Component } from "@angular/core";

import { ExternalUrl } from "../../shared/models/appData";
import { isWhiteLabel } from "../../shared/services/api/authentication.service";
import { getTranslatedUrlSignal } from "../../shared/services/language.service";

@Component({
  selector: "app-layout-public",
  templateUrl: "./layout-public.component.html",
  styleUrl: "./layout-public.component.scss",
})
export class LayoutPublicComponent {
  protected readonly isWhiteLabel = isWhiteLabel();
  protected readonly privacyUrl = getTranslatedUrlSignal(ExternalUrl.Privacy);
  protected readonly termsUrl = getTranslatedUrlSignal(ExternalUrl.Terms);
}
