<img
  *ngIf="!isWhiteLabel"
  class="logo"
  src="../../../assets/images/as_socialpals_logotype.svg"
  alt="socialPALS"
/>
<router-outlet></router-outlet>
<a *ngIf="!isWhiteLabel" class="phone" href="tel:+4980249016281">
  <span class="icon-phone"></span> +49 (0)8024 90162 81
</a>
<app-lang-selector class="header-section"></app-lang-selector>
<div class="legal-links">
  <a href="{{ privacyUrl() }}" target="_blank">{{
    "shared.privacyPolicy" | translate
  }}</a>
  <span class="separator">|</span>
  <a class="terms-and-conditions" href="{{ termsUrl() }}" target="_blank">{{
    "shared.termsAndConditions" | translate
  }}</a>
  <span class="separator">|</span>
  <a href="https://www.socialpals.de" target="_blank">socialPALS</a>
</div>
<div *ngIf="!isWhiteLabel" class="help-center">
  <a href="http://help.socialpals.de" target="_blank">
    {{ "publicLayout.helpCenter" | translate }}
  </a>
</div>
